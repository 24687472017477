<template>
  <div id="form-crud-company" class="mb-5">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      <h3>Nom & Email</h3>
      <hr />

      <form @submit.prevent="initSubmit">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="name">Nom</label>
            <input class="form-control" id="name" v-model="company.name" type="text">
            <div v-if="errorsForm.name">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.name.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input class="form-control" id="email" v-model="company.email" type="text">
            <div v-if="errorsForm.email">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.email.errors" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <h3 class="mt-4 mb-4">Téléphone & localisation</h3>
        <hr />

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="phone">Téléphone</label>
            <input class="form-control" id="phone" v-model="company.phone" type="tel">
            <div v-if="errorsForm.phone">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.phone.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="address">Adresse</label>
            <input class="form-control" id="address" v-model="company.address" type="text">
            <div v-if="errorsForm.address">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.address.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="address-2">Adresse (secondaire)</label>
            <input class="form-control" id="address-2" v-model="company.address2" type="text">
            <div v-if="errorsForm.address2">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.address2.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="city">Ville</label>
            <input class="form-control" id="city" v-model="company.city" type="text">
            <div v-if="errorsForm.city">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.city.errors" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="zip-code">Code postal</label>
            <input class="form-control" id="zip-code" v-model="company.zipCode" type="text">
            <div v-if="errorsForm.zipCode">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.zipCode.errors" :key="index">{{ error }}</p>
            </div>
          </div>
        </div>

        <input type="submit" class="btn mt-4 ml-3 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    company: Object,
    id: Number,
    actionForm: String
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      errorsForm: []
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    initSubmit()
    {
      // If company (create or update)
      if(this.actionForm === 'edit')
      {
        this.editCompany()
      }
      else {
        this.createCompany()
      }
    },
    /**
     * Create company (submit form)
     */
    createCompany() {
      this.showLoader()

      this.$store.dispatch('createCompany', this.company)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update company (submit form)
     */
    editCompany() {
      this.showLoader()

      this.$store.dispatch('updateCompany', { id: this.id, company: this.company })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to company-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'company-list' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = 'Enregistrer les modifications'
      }
      else {
        value = 'Créer une entreprise'
      }

      return value
    },
    ...mapState(['CompanyModule'])
  },
  components: {
  }
}
</script>

<style scoped>

</style>
